import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer, Button } from 'antd';
import './Header.css';
import logo from '../../../assets/logos/logo-preto-verde.svg';
import { MenuOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';

const { Header } = Layout;

export function HeaderMain() {
  const [visible, setVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathToKeyMap = {
      '/': 'hero',
      '/eventos': 'events',
    };

    const currentKey = pathToKeyMap[location.pathname] || 'hero';
    setSelectedKey(currentKey);
  }, [location.pathname]);

  const handleMenuClick = (key) => {
    setSelectedKey(key); 

    if (key === 'events') {
      navigate('/eventos');
    } else {
      if (location.pathname !== '/') {
        navigate('/');
        setTimeout(() => {
          scrollToSection(key);
        }, 100);
      } else {
        scrollToSection(key);
      }
    }
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  const menuItems = [
    { key: 'hero', label: 'Início' },
    { key: 'section', label: 'Sobre nós' },
    { key: 'services', label: 'Serviços' },
    { key: 'events', label: 'Eventos' },
    { key: 'testimonials', label: 'Equipe' },
    { key: 'footer', label: 'Contato' },
  ];

  return (
    <Header className="app-header">
      <Link to="/">
       <img src={logo} alt="Logo Orion" className="logo" />
      </Link>
      <div className="header-background" />
      <div className="header-container">
        <Button
          className="mobile-menu-button"
          onClick={showDrawer}
          icon={<MenuOutlined color="#0F172A" />}
        />
      </div>

      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[selectedKey]}
        className="menu desktop-menu"
        onClick={({ key }) => handleMenuClick(key)}
        items={menuItems}
      />

      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        open={visible}
        className="mobile-menu"
      >
        <Menu
          theme="light"
          mode="vertical"
          selectedKeys={[selectedKey]}
          onClick={({ key }) => {
            handleMenuClick(key);
            onClose();
          }}
          items={menuItems}
        />
      </Drawer>
    </Header>
  );
}
