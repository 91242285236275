import React from 'react';
import './Section.css';
import { Player } from '../../player/Player';
import { ButtonShared } from '../../shared/button/ButtonShared';

export function Section() {
  const handleClick = () => {
    window.open("https://forms.gle/q6rdW85KZBkLnEBQ8");
  }

  return (
    <><section className="section">
      <div className="text-container">
        <h2 className="title">Ciência de dados de forma simplificada e com inovação</h2>
        <div className="divider"></div>
        <p className="paragraph">
        Nosso principal talento é transformar ideias em realidade, impulsionando a sua empresa para novos patamares. 
        </p>
        <p className="paragraph">Nossa equipe de padrão de excelência, se adapta à realidade da sua empresa e realiza um diagnóstico de maturidade tecnológica, com governança, pesquisa e desenvolvimento.</p>
        <p className="paragraph">
          <strong>Assista ao vídeo de apresentação a seguir e descubra mais sobre como o Orion atua em Cidades Inteligentes usando Ciência de Dados.</strong>
        </p>
        <a href="https://www.instagram.com/reel/C-BWm-exmUr/" target='_blank'  rel="noopener noreferrer" className="link">Assistir vídeo →</a>
      </div>
      <div className="video-container">
        <Player />
      </div>
    </section> 
    <div className="div-verde">
      <ButtonShared onClick={handleClick} text={"Entrar em contato"}/>
    </div></>
  );
};

