import React from 'react';
import { Card } from 'antd';
import './Card.css'; 

const CustomCard = ({ title, description, image, category }) => {

  const getCategoryColor = (category) => {
    switch (category) {
      case 'Ciência de dados':
        return '#2DCA73'; 
      case 'Pesquisa':
        return '#FF5733'; 
      case 'Inovação':
        return '#3498db'; 
      case 'Educação':
        return '#f39c12';
      default:
        return '#333';   
    }
  };
  return (
    <Card
      hoverable
      size="small"
      className="news-card"
      cover={
        <div className="image-container">
          <img
            alt={title}
            src={image}
            className="news-card-image"
            style={{ width: '100%', height: '200px', borderRadius: '6px',objectFit: 'cover' }} 
          />
          <div className="category-tag" style={{ backgroundColor: getCategoryColor(category) }}>
            {category}
          </div>
        </div>
      }
    >
      <h3>{title}</h3>
      <p>{description}</p>
    </Card>
  );
}

export default CustomCard;
