import '../../pages/App.css';
import { HeaderMain } from '../../components/home/header/Header';
import { Services } from '../../components/home/services/Services';
import { Section } from '../../components/home/section/Section';
import { HeroSection } from '../../components/home/hero-section/HeroSection';
import { TechSection } from '../../components/home/tech-section/TechSection';
import { TestimonialSection } from '../../components/home/testimonial-section/TestimonialSection';
import { Contact } from '../../components/home/contact/Contact'
import { NewsSection } from '../../components/home/news-section/NewsSection';
import EventsSection from '../../components/home/events/Events'

function Home() {
  return (
    <div className="App">
      <HeaderMain />
      <div id="hero"><HeroSection /></div>
      <div id="section"><Section /></div>
      <div id="events"><EventsSection/></div>
      <div id="services"><Services /></div>  
      <div id="testimonials"><TestimonialSection /></div>
      {/* <div id="news"><NewsSection /></div> */}
      <div id="tech"><TechSection /></div>
      <div id="footer"><Contact/></div>
    </div>
  );
}

export default Home;
