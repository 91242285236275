import './App.css';
import  AppRoutes  from '../Routes.js';
import { Footer } from '../components/home/footer/Footer.js';

function App() {
  return (
    <div>
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
