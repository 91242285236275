
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './pages/home/index.js';
import RedirectToExternal from './RedirectToExternal.js';
import EventosPage from './pages/events/index.js';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/alla" element={<RedirectToExternal url="https://sites.google.com/orion.ufal.br/alla/" />} />
        <Route path="/eventos" element={<EventosPage/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;