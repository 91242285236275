import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { ButtonShared } from '../../shared/button/ButtonShared';
import calendar from '../../../assets/icons/calendar.svg';
import local from '../../../assets/icons/local.svg';

export default function EventCard({
    title,
    location,
    date,
    image,
    buttonText,
    buttonUrl,
    backgroundColor = "#232323",
    titleColor = "#84E094",
    textColor = "#fff",
}) {
    const handleClick = () => {
        window.open(buttonUrl);
    };

    return (
        <Card
            sx={{
                maxWidth: 400,
                backgroundColor: backgroundColor,
                borderRadius: 4,
                color: textColor,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
        >
            <CardMedia
                sx={{
                    height: 160,
                    objectFit: "cover",
                }}
                image={image}
                title={title}
            />
            <CardContent
                sx={{
                    padding: "16px",
                }}
            >
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: "8px",
                        color: titleColor,
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: textColor,
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                        marginBottom: "8px",
                    }}
                >
                    <img
                        src={local} 
                        alt="local"
                        style={{
                            width: "16px",
                            height: "16px",
                            marginRight: "8px",
                        }}
                    />
                    {location}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: textColor,
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                    }}
                >
                    <img
                        src={calendar} 
                        alt="calendar"
                        style={{
                            width: "16px",
                            height: "16px",
                            marginRight: "8px",
                        }}
                    />
                    {date}
                </Typography>
            </CardContent>
            <CardActions
                sx={{
                    padding: "0 16px 16px 16px",
                }}
            >
                <ButtonShared onClick={handleClick} text={buttonText} />
            </CardActions>
        </Card>
    );
}
