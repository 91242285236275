import React from 'react';
import './Footer.css';
import logo from '../../../assets/logos/logo-preto-verde.svg';
import youtubeIcon from '../../../assets/icons/youtube-icon.svg';
import linkedinIcon from '../../../assets/icons/linkedin-icon.svg';
import instagramIcon from '../../../assets/icons/instagram-icon.svg';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-bottom">
      <div className="copyright">
        <img src={logo} alt="logo" style={{ width: '100px', height: '28px' }} />
        {currentYear} - Todos os direitos reservados
      </div>
      <div className="social-media-icons">
        <a href="https://www.youtube.com/@orionlabufal" target="_blank" rel="noopener noreferrer">
          <img src={youtubeIcon} alt="YouTube" />
        </a>
        <a href="https://www.instagram.com/orionlab.ufal/" target="_blank" rel="noopener noreferrer">
          <img src={instagramIcon} alt="Instagram" />
        </a>
        <a href="https://www.linkedin.com/company/orionlabufal" target="_blank" rel="noopener noreferrer">
          <img src={linkedinIcon} alt="LinkedIn" />
        </a>
      </div>
    </div>
  );
}
