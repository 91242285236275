import React from 'react';
import { ButtonShared } from '../../shared/button/ButtonShared';
import './Contact.css';

export function Contact() {
  const handleClick = () => {
    window.open("https://forms.gle/q6rdW85KZBkLnEBQ8", "_blank");
  };

  return (
    <footer className="footer">
      <div className="content">
        <p>Seja uma estrela na constelação do <span className="highlight">Orion!</span></p>
        <ButtonShared onClick={handleClick} text="Preencher formulário" />
      </div>
    </footer>
  );
}
