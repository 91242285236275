import React from "react";
import "./Events.css";
import calendar from '../../../assets/icons/calendar.svg';
import local from '../../../assets/icons/local.svg';
import { ButtonShared } from '../../shared/button/ButtonShared';

const handleClick = () => {
    window.open("https://doity.com.br/orionhub-2025");
  }

const EventsSection = () => {
  return (
    <section className="orion-section">
      <div className="content-events">
        <h1>Ciência de dados aplicada às cidades inteligentes</h1>
        <div className="details">
          <p>
          <img src={calendar} alt="calendar" className="calendar" />
            Centro de Inovação do Jaraguá
          </p>
          <p>
          <img src={local} alt="local" className="local" />
            30 e 31 de janeiro das 09:00 às 20:00h
          </p>
        </div>
        <div className="details-b">
        <ButtonShared onClick={handleClick} text={"Faça sua inscrição"}/>
        </div>
      </div>
    </section>
  );
};

export default EventsSection;
