import React from 'react';
import { Row, Col, Button, Typography } from 'antd';
import CustomCard from '../../home/card/Card';
import './NewsSection.css';
import pic from '../../../assets/images/pic.png';
const newsData = [
  {
    image: pic,
    title: 'Notícia 3',
    category: 'Ciência de dados',
    description: 'Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.' 
  },
  {
    image: pic,
    title: 'Orion lança novo projeto',
    category: 'Inovação',
    description: 'Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.'
  },
  {
    image: pic,
    title: 'Hackathon TechBiz',
    category: 'Pesquisa',
    description: 'Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.'
  },
];

const { Title } = Typography;

// mudar espaçamento entre os cards
const handleClick = () => {
    window.open("https://forms.gle/q6rdW85KZBkLnEBQ8");
  }

export function NewsSection() {
  return (
    <div className="news-section">
      <Title level={1}>Últimas notícias</Title>
      <div style={{ marginTop: "40px" }}>
      <Row gutter={[16, 16]}
       justify="center">
        {newsData.map((news, index) => (
          <Col xs={24} sm={12} md={6} key={index}>
            <CustomCard {...news} />
          </Col>
        ))}
      </Row>
      </div>
      <div className="show">
      <Button onClick={handleClick} className="show-button">
          Mostrar mais
        </Button>
      </div>
    </div>
  );
};

