import "../../pages/App.css";
import { HeaderMain } from "../../components/home/header/Header";
import EventCard from "../../components/events/card/EventCard";
import OrionHubImage from "../../assets/background/orionhub.svg";

const events = [
  {
    id: 1,
    title: "Ciência de dados aplicada às cidades inteligentes",
    location: "Centro de Inovação do Jaraguá",
    date: "30 e 31 de janeiro das 09:00 às 20:00h",
    image: OrionHubImage,
    buttonText: "Faça sua inscrição",
    buttonUrl: "https://doity.com.br/orionhub-2025",
  },
];

function EventosPage() {
  return (
    <div
      className="App"
      style={{
        background: "linear-gradient(to bottom right, #6BCF7F 0%, #EFFFEF 20%, #FFFFFF 50%)",
        minHeight: "100vh",
        padding: "0",
      }}
    >
      <HeaderMain />
      <div className="eventContent" style={{ paddingLeft: "35px" }}>
        <h1 style={{ color: "#252B42", fontSize: "36px", textAlign: "left" }}>Eventos</h1>
        <p style={{ color: "#374754", fontSize: "18px", textAlign: "left" }}>
          Descubra os próximos eventos e atividades do Orion.
        </p>

        <div
          style={{
            padding: "30px 30px 30px 0",
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            justifyContent: "left",
          }}
        >
          {events.map((event) => (
            <div
              key={event.id}
              style={{
                flex: "1 1 calc(100% - 16px)",
                boxSizing: "border-box",
                maxWidth: "400px",
              }}
            >
              <EventCard
                title={event.title}
                location={event.location}
                date={event.date}
                image={event.image}
                buttonText={event.buttonText}
                buttonUrl={event.buttonUrl}
              />
            </div>
          ))}
        </div>
      </div>

      <style>
        {`
          @media (min-width: 768px) {
            .App > div > div > div {
              flex: 1 1 calc(50% - 16px); /* Em tablets, os cards ocupam 50% */
            }
          }
          @media (min-width: 1024px) {
            .App > div > div > div {
              flex: 1 1 calc(33.33% - 16px); /* Em telas maiores, ocupam 33.33% */
            }
          }
        `}
      </style>
    </div>
  );
}

export default EventosPage;
